export class BillFile {
    billFileId: bigint | undefined;
    taxId: string | undefined;
    taxName: string | undefined;
    serial: string | undefined;
    number: string | undefined;
    emitted: string | undefined;
    sendingXml: string | undefined;
    pdf: string | undefined;
    total: number | undefined;
    billTypeName: string | undefined;
    billTypeId: string | undefined;
    sendingXmlName: string | undefined;
    pdfName: string | undefined;
    saleId: bigint | undefined;
    companyId: number | undefined;
    storeId: number | undefined;
    taxUserId: string | undefined;
    taxUserName: string | undefined;

    constructor(self: BillFile) {
        this.billFileId = self.billFileId;
        this.taxId = self.taxId;
        this.taxName = self.taxName;
        this.serial = self.serial;
        this.number = self.number;
        this.emitted = self.emitted;
        this.sendingXml = self.sendingXml;
        this.pdf = self.pdf;
        this.total = self.total;
        this.billTypeName = self.billTypeName;
        this.billTypeId = self.billTypeId;
        this.sendingXmlName = self.sendingXmlName;
        this.pdfName = self.pdfName;
        this.saleId = self.saleId;
        this.companyId = self.companyId;
        this.storeId = self.storeId;
        this.taxUserId = self.taxUserId;
        this.taxUserName = self.taxUserName;
    }
}

export class Convert {
    public static toBillFile(json: string): BillFile {
        return JSON.parse(json);
    }

    public static billFileToJson(value: BillFile): string {
        return JSON.stringify(value);
    }
}
