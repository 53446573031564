import React from 'react';
import ResultView from './ResultView';
import { Bill } from './Bill';
import { BillFileService } from './BillFileService';
import { BillFile } from './BillFile';
import { DateUtil } from './DateUtil';
import EmptyView from './EmptyView';
import { ViewState } from './ViewState';
import ProgressIndicator from './ProgressIndicator';
import FaultView from './FaultView';
import logo from "./logo.png";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { Constants } from './Constants';

registerLocale('es', es)

const emptyBill = new Bill({
  taxId: '',
  emitted: undefined,
  billTypeId: '03',
  serial: '',
  number: '',
});

const mockBill = new Bill({
  taxId: '20604084092',
  emitted: DateUtil.parseFromShort('28/02/2022'),
  billTypeId: '01',
  serial: 'F001',
  number: '00002503',
});


export default function HomePage() {
  const service = new BillFileService('https://billing-files-api.sysvent.pe/billing-files-api');

  const [bill, setBill] = React.useState<Bill>(emptyBill);

  const [emitted, setEmitted] = React.useState<Date | undefined>(emptyBill.emitted);

  const [result, setResult] = React.useState<BillFile>();

  const [resultViewState, setResultViewState] = React.useState<ViewState>(ViewState.INITIAL);

  function bindTaxIdChanges(event: React.ChangeEvent<HTMLInputElement>) {
    const fTaxId = event.currentTarget.value.trim();
    bill.taxId = fTaxId;
  }

  function bindEmittedChanges(date: Date | null) {
    const currentDate = date ?? undefined;
    bill.emitted = currentDate;
    setEmitted(currentDate);
  }

  function bindBillTypeIdChanges(event: React.ChangeEvent<HTMLSelectElement>) {
    bill.billTypeId = event.currentTarget.value;
    setBill(bill);
  }

  function bindSerialChanges(event: React.ChangeEvent<HTMLInputElement>) {
    const fSerial = event.currentTarget.value.trim().toUpperCase();
    bill.serial = fSerial;
  }

  function bindNumberChanges(event: React.ChangeEvent<HTMLInputElement>) {
    const fNumber = event.currentTarget.value.trim().padStart(8, '0');
    bill.number = fNumber;
  }

  async function retrieveBillFile(bill: Bill): Promise<BillFile | undefined> {
    return await service.getOne({
      taxId: bill.taxId,
      emitted: bill.emitted,
      billTypeId: bill.billTypeId,
      serial: bill.serial,
      number: bill.number,
    });
  }

  async function trySearch(): Promise<void> {
    setResultViewState(ViewState.LOADING);
    try {
      const currentResult = await retrieveBillFile(bill);
      setResult(currentResult);
      setResultViewState(ViewState.SUCCESS);
    } catch (error) {
      console.log(error);
      setResultViewState(ViewState.FAILED);
    }
  }

  function renderDate(value: Date | undefined): string | undefined {
    return DateUtil.formatAsShort(value);
  }

  return (
    <div className="container-sm">
      <div className="row">
        <div className="col my-3">
          <div className="text-center">
            <img src={logo} />
          </div>
          <div className="text-center">
            <span className='text-muted'>Versión {Constants.VERSION}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <div className="container-md">
              <span className="navbar-brand mb-0 h1">Consulta de comprobantes</span>
            </div>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col my-3">
          <form>
            <div className="row mb-3">
              <label htmlFor="taxpayerId" className="col-sm-2 col-form-label">Ruc del emisor</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={bill.taxId}
                  onChange={(event) => bindTaxIdChanges(event)}
                />
                <span className="text-muted">Ruc de la tienda donde compraste</span>
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="emittedDate" className="col-sm-2 col-form-label">Fecha</label>
              <div className="col-sm-10">
                <DatePicker
                  locale="es"
                  dateFormat={DateUtil.defaultFormat}
                  selected={emitted}
                  onChange={(date, _) => bindEmittedChanges(date)}
                />
                <span className="text-muted">Formato DD/MM/AAAA</span>
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="type" className="col-sm-2 col-form-label">Tipo</label>
              <div className="col-sm-10">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  defaultValue={bill.billTypeId}
                  onChange={(event) => bindBillTypeIdChanges(event)}
                >
                  <option value="03">Boleta</option>
                  <option value="01">Factura</option>
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="serie" className="col-sm-2 col-form-label">Serie</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={bill.serial}
                  onChange={(event) => bindSerialChanges(event)}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="number" className="col-sm-2 col-form-label">Número</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={bill.number}
                  onChange={(event) => bindNumberChanges(event)}
                />
              </div>
            </div>
            <div className="d-grid gap-2">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => trySearch()}
              >
                Consultar
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {resultViewState === ViewState.INITIAL && <EmptyView />}
          {resultViewState === ViewState.LOADING && <ProgressIndicator />}
          {resultViewState === ViewState.FAILED && <FaultView />}
          {resultViewState === ViewState.SUCCESS && <ResultView result={result} />}
        </div>
      </div>
    </div>
  )
}
