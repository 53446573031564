import { DateTime } from "luxon";
import Mustache from "mustache";
import { BillFile, Convert } from "./BillFile";
import { BillFileEndpoints } from "./BillFileEndpoints";

interface GetOneArgs {
    taxId: string | undefined;
    emitted: Date | undefined;
    billTypeId: string | undefined;
    serial: string | undefined;
    number: string | undefined;
}

export class BillFileService {
    private baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    async getOne({
        taxId,
        emitted,
        billTypeId,
        serial,
        number,
    }: GetOneArgs): Promise<BillFile | undefined> {
        const fTaxId = taxId === undefined || !taxId.length ? '00000000000' : taxId;
        const fEmitted = DateTime.fromJSDate(emitted ?? new Date()).toFormat('yyyy-MM-dd');
        const fCodTipoDocumento = billTypeId === undefined || !billTypeId.length ? '00' : billTypeId;
        const fSerial = serial === undefined || !serial.length ? 'Z000' : serial;
        const fNumber = number === undefined || !number.length ? '00000000' : number;
        const segmentValues: any = {
            taxId: fTaxId,
            emitted: fEmitted,
            billTypeId: fCodTipoDocumento,
            serial: fSerial,
            number: fNumber,
        };
        const endpointUri = this.renderEndpoint(BillFileEndpoints.GET_ALL, segmentValues);
        const requestUrl = `${this.baseUrl}/${endpointUri}`;
        console.log(`Request GET to ${requestUrl}`);
        const response = await fetch(requestUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
        const responseStatus = response.status;
        const responseBody = await response.text();
        console.log(`Response status ${responseStatus}`);
        console.log(`Response body ${!responseBody.length ? 'is empty' : responseBody}`);
        if (response.status === 200) {
            return !responseBody.length ? undefined : Convert.toBillFile(responseBody);
        }
        throw new Error('An error occurred on the server side');
    }

    private renderEndpoint(endpoint: string, segmentValues: any): string {
        return Mustache.render(endpoint, segmentValues);
    }
}