import React from "react";
import { BillFile } from "./BillFile";
import { DateUtil } from "./DateUtil";

interface ResultViewProps {
  result: BillFile | undefined;
}

export default function ResultView({ result }: ResultViewProps) {

  function renderDate(value: string | undefined): string | undefined {
    const asDate = DateUtil.parseFromIso(value);
    return DateUtil.formatAsShort(asDate);
  }

  return (
    <React.Fragment>
      {result === undefined ? (
        <div className="text-center">
          <h1>
            Sin resultados
          </h1>
          <p>
            Revise los datos ingresados y vuelva a intentarlo
          </p>
        </div>
      ) : (
        <div className="text-center">
          <h1>
            Comprobante encontrado
          </h1>
          <p className="my-0">
            {result.billTypeName ?? 'Sin tipo'}
          </p>
          <p className="my-0">
            {result.serial ?? 'Sin serie'}-{result.number ?? 'Sin número'}
          </p>
          <p className="my-0">
            Emitida el {renderDate(result.emitted) ?? 'Sin fecha'}
          </p>
          <p className="my-0">
            {result.taxUserName ?? 'Sin nombre'}
          </p>
          <p className="fs-2 fw-bold text-secondary">
            S/ {result.total?.toFixed(2) ?? 'Sin monto'}
          </p>
          {result.pdf === undefined || result.pdfName === undefined ? (
            <span
              className="mr-5"
            >
              Sin PDF
            </span>
          ) : (
            <a
              className="link-primary mr-5"
              href={`data:application/octet-stream;base64,${result.pdf}`}
              download={result.pdfName}
            >
              Descargar PDF
            </a>
          )}
          <span className="mx-4"></span>
          {result.sendingXml === undefined || result.sendingXmlName === undefined ? (
            <span
              className="mr-5"
            >
              Sin XML
            </span>
          ) : (
            <a
              className="link-primary ml-5"
              href={`data:application/octet-stream;base64,${result.sendingXml}`}
              download={result.sendingXmlName}
            >
              Descargar XML
            </a>
          )}
        </div>
      )}
    </React.Fragment>
  )
}
