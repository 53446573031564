export class Bill {
    taxId: string | undefined;
    emitted: Date | undefined;
    billTypeId: string | undefined;
    serial: string | undefined;
    number: string | undefined;

    constructor(self: Bill) {
        this.taxId = self.taxId;
        this.emitted = self.emitted;
        this.billTypeId = self.billTypeId;
        this.serial = self.serial;
        this.number = self.number;
    }
}