import React from 'react';

export default function FaultView() {
    return (
        <div className="text-center">
            <h1 className="text-danger">
                Algo salió mal
            </h1>
            <p>
                Vuelve a intentarlo en unos minutos
            </p>
        </div>
    )
}
