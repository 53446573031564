import { DateTime } from "luxon";

export class DateUtil {
    static readonly defaultFormat: string = 'dd/MM/yyyy';

    static formatAsShort(value: Date | undefined): string | undefined {
        if (value === undefined) {
            return undefined;
        }
        return DateTime.fromJSDate(value).toFormat(DateUtil.defaultFormat);
    }

    static parseFromShort(value: string | undefined): Date | undefined {
        if (value === undefined) {
            return undefined;
        }
        if (value.length !== 10) {
            return undefined;
        }
        return DateTime.fromFormat(value, DateUtil.defaultFormat).toJSDate();
    }

    static parseFromIso(value: string | undefined): Date | undefined {
        if (value === undefined) {
            return undefined;
        }
        return DateTime.fromISO(value).toJSDate();
    }
}