import React from 'react';

export default function ProgressIndicator() {
    return (
        <div className="text-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Cargando...</span>
            </div>
        </div>
    )
}
